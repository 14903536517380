<template>
  <div class="nei-rong">
    <van-tree-select
        v-model:active-id="activeId"
        v-model:main-active-index="activeIndex"
        :items="items"
    />
    <div class="text-center an-niu">
      <van-button type="primary" @click="yes" size="normal">
        确定
      </van-button>
    </div>
  </div>
</template>

<script >
import axios from "@/api/http.js";
//多选项目类型
export default {
  name: 'DuoXuan',
  props: {
    //checkbox单选、radio多选
    inputType: {
      type: String,
      default() {
        return 'checkbox'
      }
    },
    dataType: {
      type: String,
      default() {
        return 'xm_type'
      }
    }
  },
  setup() {
  },
  data() {
    return {
      dataUrl: {
        xm_type: {
          url: "/portal/index/xiangMuType4"
        },
        fu_wu_di_qu: {//服务地区
          url: '/portal/index/get_di_qu',
        }
      },
      items: [],
      activeId: [],
      activeIndex: 0,
      name: [],
      form_list: [],//表单格式数据
    }
  },

  mounted() {
    if (this.inputType === "radio") {
      this.activeId = this.activeId.join(',')
    }
    let self = this
    axios.get(this.dataUrl[this.dataType].url).then((da) => {
      if (da.code == 1) {
        self.items = da.data
      }
    })
  },
  methods: {
    yes() {
      let self = this
      let selfId = this.activeId
      if (Array.isArray(selfId)) selfId.join(',')
      if (this.activeId == '') {
        this.event([])
        return
      }
      //获取数据
      axios.get("/portal/index/get_nav_name", {
        params: {id: selfId}
      }).then((da) => {
        if (da.code == 1) {
          this.event(da.data)
        }

      })
    },
    setDefault(activeId) {
      this.activeId = activeId
      this.yes()
    },
    event(data) {
      let self = this
      self.name = []
      self.form_list = []
      data.forEach(function (n) {
        self.name.push(n.name)
        self.form_list.push({
          name: n.name,
          id: n.id,
          parent_id: n.parent_id
        })
      })
      this.$emit("update:form", this.form_list)
      this.$emit("dataEdit", data, this.name, this.activeId)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.an-niu {
  margin-top: 30px;
  overflow-y: auto;
}
</style>
