<template>
  <van-field :required="required"
             @click="showSelevtEvent"
             readonly
             autosize
             rows="1"
             type="textarea"
             v-model="value"
             :label="title"
             :placeholder="placeholder"/>
  <van-popup

      v-model:show="showSelevt"
      position="bottom"
      round
      teleport="body"
  >
    <DuoXuan
        :dataType="dataType"
        :inputType="inputType"
        ref="duoXuan"
        v-model:form="form_list"
        @dataEdit="dataEdit"
    />
    <div style="height: 10px;"></div>
  </van-popup>
</template>

<script>
import {reactive} from 'vue';
import {Options, Vue} from 'vue-class-component';
import DuoXuan from "@/components/select/DuoXuan.vue";
import axios from "@/api/http";
import XEUtils from "xe-utils";
//选择服务商类型
/**
 *
 <fu-wu-shang-type
 v-model:id="form.type_id"
 v-model:name="form.type_name"
 input-type="radio"
 required
 title="案例类型"
 />
 */
export default {
  nmae: 'FuWuShangType',
  components: {DuoXuan},
  props: {
    //checkbox=多选   radio=单选
    inputType: {
      type: String,
      default() {
        return 'checkbox'
      }
    },
    dataType: {
      type: String,
      default() {
        return 'xm_type'
      }
    },
    //默认显示的id 会自动化显示数据 推荐使用
    id: {
      type: String
    },
    name: {
      type: String
    },
    required: {
      type: Boolean,
      default() {
        return false
      }
    },
    readonly: {
      type: Boolean,
      default() {
        return false
      }
    },
    //默认值
    val: {
      type: String,
      default() {
        return ''
      }
    },
    //提示
    placeholder: {
      type: String,
      default() {
        return '请选择'
      }
    },
    //标题
    title: {
      type: String,
      default() {
        return ''
      }
    }

  },

  data() {
    return {
      showSelevt: false,
      value: this.val,
      form_list: []
    }
  },

  mounted() {

  },
  methods: {
    showSelevtEvent() {
      if (!this.readonly) {
        this.showSelevt = true
      }

    },
    dataEdit() {
      this.value = this.form_list.map(n => n.name).join('、')
      this.$emit("update:form", this.form_list)//id字符串
      this.$emit("update:id", this.form_list.map(n => n.id).join(","))//id字符串
      this.$emit("update:name", this.form_list.map(n => n.name).join(","))//名称字符串
      this.showSelevt = false
    },
    //请空参数
    set(val) {
      this.value = val;
      this.$emit("update:form", val)//id字符串
      this.$emit("update:id", val)//id字符串
      this.$emit("update:name", val)//名称字符串
    },
    setDefault(params) {
      axios.get("/portal/index/get_nav_menu", {
        params: params
      }).then((da) => {
            if (da.code == 1) {
              this.form_list = da.data.map(n => {
                return {
                  id: n.id,
                  name: n.name,
                  parent_id: n.parent_id
                }
              })
              this.dataEdit()
            }
          }
      )
    }
  },
  watch: {
    id: {
      handler(newVal) {
        if (XEUtils.isUndefined(newVal)) return
        this.setDefault({id: this.id})
      },
      immediate: false
    },
    name: {
      handler(newVal) {
        //兼容只要名称的
        if (!XEUtils.isUndefined(this.id)) return
        if (XEUtils.isUndefined(newVal)) return
        this.value = newVal
      },
      immediate: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
